import styled from 'preact-emotion';

const Paragraph = styled('p')`
  max-width: 700px;
  margin: 0 0 1.3rem;
  font-size: 1.8rem;
  line-height: 1.4em;
`;

export default Paragraph;
