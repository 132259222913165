import { h } from 'preact';
import styled from 'preact-emotion';

import NoWrap from './NoWrap';

const Detail = styled(NoWrap)`
  font-size: 0.5em;
  font-weight: normal;
  color: #999;
`;

const HeadingWithDetail = ({ className, level = 2, children, detail }) => {
  const Heading = `h${level}`;
  return (
    <Heading className={className}>
      {children} <Detail>{detail}</Detail>
    </Heading>
  );
};

export default styled(HeadingWithDetail)`
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: ${props => props.size || 3}rem;
  font-weight: normal;
`;
