import { h } from 'preact';
import { css } from 'emotion';
import styled from 'preact-emotion';

import HeadingWithDetail from './HeadingWithDetail';
import Paragraph from './Paragraph';

const className = css`
  margin-bottom: 6rem;
`;

const Heading = styled(HeadingWithDetail)`
  margin: 0 0 0.5rem;
`;

const SubHeading = styled('div')`
  font-family: 'Open Sans Condensed', sans-serif;
  margin-bottom: 2rem;
  font-size: 2.4rem;
`;

const Dates = styled('div')`
  font-size: 1.6rem;
  color: #888;
`;

const Job = props => (
  <div className={className}>
    <Heading detail={props.location} level={3} size={4}>
      {props.company}
    </Heading>
    <SubHeading>
      {props.title}
      <Dates>
        {props.dates.start} to {props.dates.end}
      </Dates>
    </SubHeading>
    {props.description.map(p => <Paragraph>{p}</Paragraph>)}
  </div>
);

export default Job;
