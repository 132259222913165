import { h } from 'preact';
import { css, injectGlobal } from 'emotion';
import styled from 'preact-emotion';

import cv from './cv.yaml';

import HeadingWithDetail from './components/HeadingWithDetail';
import Section from './components/Section';
import Paragraph from './components/Paragraph';
import Job from './components/Job';

injectGlobal`
  html {
    font-size: 10px;
  }

  body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
  }

  * {
    box-sizing: border-box;
  }
`;

const className = css`
  margin: 0 auto;
  max-width: 1000px;
  padding: 16rem 8rem;
`;

const H1 = styled('h1')`
  font-weight: normal;
  margin: 0 0 3rem;
`;

const Name = styled('span')`
  font-family: 'Open Sans Condensed', sans-serif;
  /* text-transform: uppercase; */
  font-size: 6rem;
`;

const Title = styled('span')`
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 3rem;
  color: #888;
`;

const CV = () => (
  <main className={className}>
    <HeadingWithDetail level={1} detail={cv.title} size={6}>
      {cv.name}
    </HeadingWithDetail>
    <Section>{cv.profile.map(p => <Paragraph>{p}</Paragraph>)}</Section>
    <Section heading="Employment">
      {cv.employment.map(job => <Job {...job} />)}
    </Section>
    {/*<Section heading="Technologies + Skills">
      <H3>Current</H3>
      <Paragraph>
        JavaScript (ES2015+)/TypeScript, React/Vue, Sass/Styled Components,
        Redux/MobX, Jest, Webpack/Parcel, Node, Express, SuperTest, Git, AWS,
        continuous integration, agile methodologies
      </Paragraph>
      <H3>Past</H3>
      <Paragraph>
        PHP + MySQL, C, C++, Java, Visual Basic, JavaScript (ES5), Angular,
        jQuery, Subversion, Apache
      </Paragraph>
</Section>*/}
  </main>
);

export default CV;
