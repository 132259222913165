import { h } from 'preact';
import { css } from 'emotion';
import styled from 'preact-emotion';

const className = css`
  margin-bottom: 12rem;
`;

const SectionHeading = styled('h2')`
  margin: 0 0 2rem;
  font-weight: normal;
  font-size: 1.6rem;
  text-transform: uppercase;
`;

const Section = ({ heading, children }) => (
  <section className={className}>
    {heading ? <SectionHeading>{heading}</SectionHeading> : null}
    {children}
  </section>
);

export default Section;
